// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import {getDateWithoutTime} from '@/libs/helper'

export default function useCalendar() {

    const toast = useToast()

    const refCalendar = ref(null)
    
    let calendarApi = null

    const resetView = () => {
        calendarApi.changeView('dayGridMonth')
    }


    const refetchEvents = () => {
        calendarApi.refetchEvents()
    }

    const fetchEvents = (info, successCallback) => {
        if (!info) 
            return
        // store.dispatch('meeting/fetchEvents', {calendars: selectedCalendars.value, is_admin:false}).then(response => {
        store.dispatch('admin-leaverecord/fetchFullLeaveRecords', {date_from: getDateWithoutTime(info.startStr), date_to: getDateWithoutTime(info.endStr)}).then(response => {
            let leaverecords = []
            
            // If neet to set the day correctly, first need to change the full day to false, then set the end to "T23:59:00"

            response.data.leaverecords.forEach(item => {
               // console.log(item.end)
                let color = (item.approve_bit == 1) ? (item.record_bit == 1) ? '#A5853D' : 'lightgreen' : 'pink';
                leaverecords.push({...item,end:item.end_day+'T23:59:00',color,allDay:false})
            })

            response.data.holidays.forEach(holiday => {
                leaverecords.push({start:holiday.holiday_date,end: holiday.holiday_date, color:'red', display: 'background', 'holiday_name': holiday.holiday_name})
            })

            //leaverecords.push({start:'2022-04-16', end: '2022-04-16', color:'red',display: 'background'})

            successCallback(leaverecords)
        }).catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching calendar events',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    const calendarOptions = ref({
        plugins: [
            dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin
        ],
        initialView: 'dayGridMonth',
        headerToolbar: {
            start: 'sidebarToggle, prev,next, title',
            end: 'dayGridMonth,listMonth'
        },
        //timeZone: 'Asia/Hong_Kong',
        events: fetchEvents,
        editable: false,
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 50,
        eventContent: function (arg) {
            //console.log('arg', arg.event)

            var event = arg.event

            let record = event._def.extendedProps

            //console.log('record', record)

            var customHtml = ''
           
            //console.log('arg.event.background', arg.event.display)

            if(arg.event.display != 'background')
            {
                let color = (event._def.extendedProps.approve_bit == 1) ? (event._def.extendedProps.record_bit == 1) ? 'linear-gradient(118deg,#a5853d,rgba(165,133,61,.7))' : 'lightgreen' : 'pink';

                customHtml += "<div style='position:relative; padding:5px; border-radius:5px; width:100%; overflow: hidden; background:" + color + "; color:white; box-shadow:0 0 10px 1px rgb(165 133 61 / 70%);'>"
    
                customHtml += "<span style='margin-top:5px;'>" + record.name + "</span><br>"
    
                if(record.description)
                {
                    customHtml += "<span style='margin-top:5px;'>" + record.description + "</span><br>"
                }
    
    
    
                if(record.full_day_bit)
                {
                    customHtml += "<span style='margin-top:5px;'>Full Day</span>"
                }else{
                    if(record.time_from)
                    {
                        customHtml += "<span style='margin-top:5px;'>"+record.time_from.slice(0, 5)+"</span><br>"
                    }
                    if(record.time_to)
                    {
                        customHtml += "<span style='margin-top:5px;'>"+record.time_to.slice(0, 5)+"</span>"
                    }
                }
    
    
                customHtml += "</div>";
            }else{
                customHtml += '<br><span style="color:white;">'+record.holiday_name+'</span>'
            }

            

            return {html: customHtml}
        },
        eventResize(
            {event: resizedEvent}
        ) {
            updateEvent(grabEventDataFromEventApi(resizedEvent))
        },
        direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
        rerenderDelay: 350,
        
        


    })

    return {
        refCalendar,
        calendarOptions,
        refetchEvents,
        fetchEvents,
        resetView,
    
    }


}
