<template>
  <div>
    <leave-record-add-new
      :is-add-new-leave-record-sidebar-active.sync="isAddNewLeaveRecordSidebarActive"
      :id="id"
      :data="leaverecordData"
      :leavecodes="leavecodes"
      :holidays="holidays"
      @refetch-data="refetchData"
      v-if="ability.can('create', 'leaverecords')"
    >
    </leave-record-add-new>

    <modal-calendar :leave-data="leaveData" />
    <div
      class="annual-leave-statistic-container"
      v-if="user && user.day_of_employment && dateFromFilter"
    >
      <div class="row">
        <div :class="`col-md-${2 + (show_date_remain ? 0 : 1)}`">
          <div class="stat-box">
            <div class="title pos">{{ total_annual_leave }}</div>
            <div class="body">總年假天數</div>
          </div>
        </div>
        <div :class="`col-md-${2 + (show_date_remain ? 0 : 1)}`">
          <div class="stat-box">
            <div class="title neg">{{ annual_leave_used }}</div>
            <div class="body">已用年假天數</div>
          </div>
        </div>
        <div :class="`col-md-${2 + (show_date_remain ? 0 : 1)}`">
          <div class="stat-box">
            <div class="title pos">
              {{ total_annual_leave - annual_leave_used }}
            </div>
            <div class="body">剩餘年假天數</div>
          </div>
        </div>

        <div
          :class="`col-md-${2 + (show_date_remain ? 0 : 1)}`"
          v-if="show_date_remain"
        >
          <div class="stat-box">
            <div class="title pos">{{ date_remain }}</div>
            <div class="body">剩餘天數</div>
          </div>
        </div>

        <div
          :class="`col-md-${2 + (show_date_remain ? 0 : 1)}`"
        >
          <div class="stat-box">
            <div class="title pos">{{ user.day_of_employment }}</div>
            <div class="body">Day of Employment</div>
          </div>
        </div>
      </div>
    </div>
    <b-row v-if="leave_statistics && false">
      <b-col
        lg="2"
        sm="12"
        v-for="(item, index) in leave_statistics"
        :key="`item_${index}`"
      >
        <statistic-card-vertical
          :statistic="item.total_days"
          :statistic-title="item.name"
        />
      </b-col>
    </b-row>
    <leave-record-list-filter
      :user-filter.sync="userFilter"
      :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter"
      :user-options="userOptions"
      :leave-options="leaveOptions"
      :leave-filter.sync="leaveFilter"
    />
    <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
              <b-button variant="success" class="mr-1" @click="exportExcel">
                <feather-icon icon="FileIcon" size="18" class="" />
              </b-button>
              <b-button variant="success" v-b-modal.modal-calendar>
                <feather-icon icon="CalendarIcon" size="18" class="" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refLeaveRecordListTable"
        class="position-relative"
        :items="fetchLeaveRecords"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(full_day_bit)="data">
          <feather-icon
            v-if="data.item.full_day_bit"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>

        <template #cell(file_path)="data">
          <div class="block" v-if="data.item.file_path">
            <el-image
              style="width: 100px; height: 100px"
              :src="getImagePath(data.item.file_path)"
              :preview-src-list="getList(getImagePath(data.item.file_path))"
              fit="fill"
            ></el-image>
          </div>
        </template>

        <template #cell(count_al_balance)="data">
          <feather-icon
            v-if="data.item.count_al_balance"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <template #cell(approve_bit)="data">
          <!--
          <feather-icon
            v-if="data.item.approve_bit"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />-->
          <b-form-checkbox
            v-model="data.item.approve_bit"
            value="1"
            class="custom-control-success"
            @change="updateApproveBit(data.item.id, data.item.approve_bit)"
          />
        </template>
        <template #cell(record_bit)="data">
          <!--
          <feather-icon
            v-if="data.item.record_bit"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />-->
          <b-form-checkbox
            v-model="data.item.record_bit"
            value="1"
            class="custom-control-success"
            @change="updateRecordBit(data.item.id, data.item.record_bit)"
          />
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'leaverecords')"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('delete', 'leaverecords')"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLeaveRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BCardHeader,
  BCardBody,
  BOverlay
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import leaverecordStoreModule from "../leaverecordStoreModule";
import leavecodeStoreModule from "@/views/setting/leavecode/leavecodeStoreModule";
import useLeaveRecordsList from "./useLeaveRecordsList";
import LeaveRecordAddNew from "./LeaveRecordListAddNew.vue";
import LeaveRecordListFilter from "./LeaveRecordListFilter.vue";
import ModalCalendar from "../modal/ModalCalendar.vue";
import StatisticCardVertical from "./StatisticCardVertical.vue";
import {
  getNextCycleEndDate,
  getPreviousYear,
  getNextYear,
  dateDifferent,
} from "@/libs/helper";

export default {
  components: {
    StatisticCardVertical,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    LeaveRecordAddNew,
    LeaveRecordListFilter,
    ModalCalendar,
    BCardHeader,
    BCardBody,
    BOverlay
  },
  methods: {
    exportExcel() {
      this.$swal({
        title: "確定下載?",
        text: "下載所有請假記錄",
        showCancelButton: true,
        confirmButtonText: "下載",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store.dispatch("admin-leaverecord/exportAllExcel").then((response) => {
            this.loading = false;
            const contentDispositionHeader =
              response.headers["content-disposition"];
            let filename ='all_leave_record'; // 默认文件名，如果无法从头中解析

            if (contentDispositionHeader) {
              const filenameMatch =
                contentDispositionHeader.match(/filename="?(.+)"?/);
              if (filenameMatch && filenameMatch.length > 1) {
                // 这里我们假设服务器返回的文件名是有效的，不需要额外解码
                filename = filenameMatch[1];
              }
            }

            // 处理Blob和创建下载链接
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", filename); // 使用从头信息中提取的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl); // 清理blob URL
          }).catch(error => {
            this.loading = false;
          });
        }
      }).catch(error => {
        console.log(error)
        this.loading = false;
      })
      
      ;
    },
    getList(item) {
      return [item];
    },
    getImagePath(file_path) {
      return process.env.VUE_APP_IMAGE_URL + file_path;
    },
    updateApproveBit(id, approve_bit) {
      store
        .dispatch("admin-leaverecord/updateLeaveRecord", { id, approve_bit })
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        });
    },
    updateRecordBit(id, record_bit) {
      store
        .dispatch("admin-leaverecord/updateLeaveRecord", { id, record_bit })
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        });
    },
    handleCreate() {
      this.id = 0;
      this.resetLeaveRecordData();
      this.isAddNewLeaveRecordSidebarActive = true;
    },
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "你的操作是最終的，將無法恢復!",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("admin-leaverecord/deleteLeaveRecord", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleEdit(item) {
      console.log(item);
      this.id = item.id;
      this.leaverecordData.id = item.id;
      this.leaverecordData.count_al_balance = item.count_al_balance;
      this.leaverecordData.approve_bit = item.approve_bit;
      this.leaverecordData.record_bit = item.record_bit;
      this.leaverecordData.admin_remark = item.admin_remark;
      this.leaverecordData.total_days = item.total_days;
      this.leaverecordData.date_from = item.date_from;
      this.leaverecordData.date_to = item.date_to;
      this.leaverecordData.time_from = item.time_from;
      this.leaverecordData.time_to = item.time_to;
      this.leaverecordData.full_day_bit = item.full_day_bit;
      this.leaverecordData.name = item.name;
      this.leaverecordData.leave_code_id = item.leave_code_id;
      this.isAddNewLeaveRecordSidebarActive = true;
    },
    resetLeaveRecordData() {
      this.leaverecordData = {
        id: 0,
        admin_remark: "",
        total_days: 0,
        date_from: "",
        date_to: "",
        time_from: "",
        time_to: "",
        count_al_balance: false,
        approve_bit: false,
        full_day_bit: false,
      };
    },
  },
  mounted() {
    //  console.log('Leave Record Created')
    //  this.$bvModal.show("modal-calendar")
    //this.$bvModal.hide("modal-calendar");
  },
  data() {
    return {
      id: 0,
      leaverecordData: {},
    };
  },
  computed: {
    current_employment_cycle() {
      if (this.user) {
        return this.user.current_employment_cycle;
      }
      return null;
    },
    annual_leave_used() {
      //sum of the leave_records
      if (!this.user) {
        return null;
      }
      let annual_leave_used = this.leave_records.reduce(
        (accumulator, currentValue) => {
          if (currentValue.count_al_balance) {
            return accumulator + currentValue.total_days;
          } else {
            return accumulator;
          }
        },
        0
      );

      return annual_leave_used;
    },
    total_annual_leave() {
      
      if (this.dateToFilter) {
        let original_annual_leave = (this.user && this.user.annual_leave_entitlement) || 12
        return Math.max(
          original_annual_leave,
          Math.min(
            Math.round(
              dateDifferent(this.user.day_of_employment, this.dateToFilter) /
                365
            ) -
              1 +
              original_annual_leave,
            21
          )
        );
      } else {
        return original_annual_leave;
      }
    },
    date_remain() {
      //get current date string
      let today = new Date();
      return dateDifferent(today, this.dateToFilter);
    },
    show_date_remain() {
      return this.date_remain >= 0 && this.date_remain <= 365;
    },
  },
  watch: {
    userFilter(newVal) {
      if (newVal) {
        this.dateFromFilter = newVal.current_employment_cycle;
      } else {
        this.dateFromFilter = null;
      }
    },
    current_employment_cycle() {
      this.dateFromFilter = this.current_employment_cycle;
    },
    dateFromFilter(newVal, oldVal) {
      console.log({ newVal });
      if (newVal == null) {
        this.dateToFilter = null;
      } else {
        this.dateToFilter = getNextCycleEndDate(newVal);
      }
      //newVal is a date, i would like to get newVal + 1 year - 1 day
    },
  },
  setup() {
    const ADMIN_LEAVE_RECORD_STORE_MODULE_NAME = "admin-leaverecord";
    const SETTING_LEAVE_CODE_STORE_MODULE_NAME = "setting-leavecode";
    const leavecodes = ref([]);
    const loading = ref(false);
    const holidays = ref([]);

    if (!store.hasModule(ADMIN_LEAVE_RECORD_STORE_MODULE_NAME))
      store.registerModule(
        ADMIN_LEAVE_RECORD_STORE_MODULE_NAME,
        leaverecordStoreModule
      );
    if (!store.hasModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME))
      store.registerModule(
        SETTING_LEAVE_CODE_STORE_MODULE_NAME,
        leavecodeStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(ADMIN_LEAVE_RECORD_STORE_MODULE_NAME))
        store.unregisterModule(ADMIN_LEAVE_RECORD_STORE_MODULE_NAME);
      if (store.hasModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME);
    });

    store
      .dispatch(SETTING_LEAVE_CODE_STORE_MODULE_NAME + "/fetchLeaveCodes")
      .then((response) => {
        leavecodes.value = response.data.leavecodes;
      });

    const isAddNewLeaveRecordSidebarActive = ref(false);

    const {
      fetchLeaveRecords,
      tableColumns,
      perPage,
      currentPage,
      totalLeaveRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLeaveRecordListTable,
      refetchData,
      ability,
      userFilter,
      dateFromFilter,
      dateToFilter,
      leaveData,
      leaveFilter,
      leave_statistics,
      user,
      leave_records,
      
    } = useLeaveRecordsList();

    // Filter
    const userOptions = ref([]);
    const leaveOptions = ref([]);

    store
      .dispatch("app/fetchOptionList", { user: true, leave_code: true, holiday: true,})
      .then((response) => {
        userOptions.value = response.data.users;
        leaveOptions.value = response.data.leave_codes;
        holidays.value = response.data.holidays;
      })
      .catch((error) => {
        console.log(error);
      });

    return {
      //Sidebar
      isAddNewLeaveRecordSidebarActive,

      fetchLeaveRecords,
      tableColumns,
      perPage,
      currentPage,
      totalLeaveRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLeaveRecordListTable,
      refetchData,
      avatarText,
      ability,
      leavecodes,
      //Filter
      userFilter,
      dateFromFilter,
      dateToFilter,
      userOptions,
      leaveOptions,
      leaveData,
      leaveFilter,
      leave_statistics,
      user,
      leave_records,
      loading,
      holidays
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.annual-leave-statistic-container {
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(180, 180, 180, 0.5);
  margin-bottom: 50px;
  border-radius: 10px;
}

.stat-box {
  padding: 20px;
  text-align: center;

  .title {
    font-family: "Lobster", cursive;
    font-size: 2em;

    &.pos {
      color: #00b16a;
    }

    &.neg {
      color: #f03434;
    }
  }

  .body {
    font-size: 18px;
    color: #555;
  }
}
</style>