import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLeaveCodes(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/leave_code/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchLeaveCode(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/leave_code/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addLeaveCode(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/leave_code', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    }

  },
}
