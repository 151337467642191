<template>
    <b-sidebar
      id="add-new-leave-record-sidebar"
      :visible="isAddNewLeaveRecordSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="
        (val) => $emit('update:is-add-new-leave-record-sidebar-active', val)
      "
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            {{ button_text }} Leave Record [{{ leaverecordData.name }}]
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-overlay
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Leave Record-->
  
              <h6>Leave Detailssss</h6>
              <hr />
              <b-form-group
                class="required"
                label="Type of Leave"
                label-for="leave_code_id"
              >
                <v-select
                  id="leave_code_id"
                  v-model="leaverecordData.leave_code_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leavecodes"
                  label="description"
                  :reduce="(option) => option.id"
                  :clearable="false"
                >
                  <template slot="option" slot-scope="option">
                    [{{ option.leave_code }}] {{ option.description }}
                  </template>
                </v-select>
              </b-form-group>
  
              <validation-provider
                  #default="validationContext"
                  v-if="reason_bit"
                  :key="'reason_bit'+reason_bit"
                  name="reason"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Reason"
                    label-for="reason"
                  >
                    <b-form-input
                      id="reason"
                      v-model="leaverecordData.reason"
                      :state="getValidationState(validationContext)"
                      placeholder="Write your reason"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
              </validation-provider>
  
              <validation-provider
                #default="validationContext"
                name="date_from"
                rules="required"
              >
                <b-form-group
                  class="date_from required"
                  label="Dates of Absence"
                  label-for="date_from"
                >
                  <b-form-datepicker
                    id="date_from"
                    :state="getValidationState(validationContext)"
                    placeholder="Choose a date"
                    local="en"
                    v-model="leaverecordData.date_from"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
  
              <validation-provider
                #default="validationContext"
                name="date_to"
                rules="required"
              >
                <b-form-group class="date_to" label="to" label-for="date_to">
                  <b-form-datepicker
                    id="date_to"
                    :state="getValidationState(validationContext)"
                    placeholder="Choose a date"
                    local="en"
                    v-model="leaverecordData.date_to"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group
                class="full_day_bit"
                label=""
                label-for="full_day_bit"
              >
                <b-form-checkbox
                  v-model="leaverecordData.full_day_bit"
                  value="1"
                  unchecked-value="0"
                  class="custom-control-primary"
                  :disabled="date_of_leave.length > 1 == 1 ? true : false"
                  @change="full_day_bit_changed()"
                >
                  <span
                    :class="{ time_selected: leaverecordData.full_day_bit == 1 }"
                    >Full Day</span
                  >
                </b-form-checkbox>
              </b-form-group>
              <b-form-group class="am_bit" label="" label-for="am_bit">
                <b-form-checkbox
                  v-model="leaverecordData.am_bit"
                  value="1"
                  unchecked-value="0"
                  class="custom-control-primary"
                  :disabled="date_of_leave.length > 1 == 1 ? true : false"
                  @change="am_bit_changed()"
                >
                  <span :class="{ time_selected: leaverecordData.am_bit == 1 }"
                    >A.M. - Half Day (09:00 - 13:00)</span
                  >
                </b-form-checkbox>
              </b-form-group>
  
              <b-form-group class="" label="" label-for="pm_bit">
                <b-form-checkbox
                  v-model="leaverecordData.pm_bit"
                  value="1"
                  unchecked-value="0"
                  class="custom-control-primary"
                  :disabled="date_of_leave.length > 1 == 1 ? true : false"
                  @change="pm_bit_changed()"
                >
                  <span :class="{ time_selected: leaverecordData.pm_bit == 1 }"
                    >P.M. - Half Day (14:00 - 18:00)</span
                  >
                </b-form-checkbox>
              </b-form-group>
  
              <br />
              <h6>Approve</h6>
              <hr />
              <b-form-group label="Total Days" label-for="total_days">
                <b-form-input
                  id="total_days"
                  v-model="leaverecordData.total_days"
                />
              </b-form-group>
              <!-- Admin Remark -->
              <b-form-group label="Admin Remark" label-for="admin_remark">
                <b-form-textarea
                  id="admin_remark"
                  v-model="leaverecordData.admin_remark"
                  autofocus
                  trim
                />
              </b-form-group>
  
              <!-- Invoicing Bit -->
              <b-form-group>
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  switch
                  inline
                  v-model="leaverecordData.count_al_balance"
                >
                  Count AL
                </b-form-checkbox>
              </b-form-group>
  
              <!-- Timesheet Bit -->
              <b-form-group>
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  switch
                  inline
                  v-model="leaverecordData.approve_bit"
                >
                  Approve
                </b-form-checkbox>
              </b-form-group>
  
              <hr />
  
              <!-- Form Actions -->
  
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  {{ button_text }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </b-overlay>
        </validation-observer>
      </template></b-sidebar>
  </template>
  <script>
  
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { ref, watch } from "@vue/composition-api";
  import { required, alphaNum, email } from "@validations";
  import { max } from "@/libs/validations.js";
  import formValidation from "@core/comp-functions/forms/form-validation";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import store from "@/store";
  import { useToast } from "vue-toastification/composition";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormTextarea,
      BFormCheckbox,
      vSelect,
      BOverlay,
      BFormDatepicker,
      BFormTimepicker,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isAddNewLeaveRecordSidebarActive",
      event: "update:is-add-new-leave-record-sidebar-active",
    },
    props: {
      isAddNewLeaveRecordSidebarActive: {
        type: Boolean,
        required: true,
      },
      id: {
        type: Number,
        required: true,
      },
      data: {
        type: Object,
        required: true,
      },
      leavecodes: {
        type: Array,
        required: true,
      },
      holidays: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        max,
        reason_bit:0
      };
    },
    computed: {
      day_of_leave() {
        return this.leaverecordData.date_from - this.leaverecordData.date_to;
      },
      type_of_leave() {
        return this.leaverecordData.leave_code_id;
      },
    },
    watch: {
      type_of_leave() {
        var type_of_leave = this.leavecodes.filter(
          (item) => item.id == this.leaverecordData.leave_code_id
        );
        type_of_leave = type_of_leave[0];
  
        this.leaverecordData.count_al_balance = type_of_leave.count_al_balance;
        this.reason_bit = type_of_leave.reason_bit;
        this.leaverecordData.reason = this.reason_bit ? this.leaverecordData.reason : ''
        
      },
      day_of_leave() {
        var startDate = new Date(this.leaverecordData.date_from);
        var endDate = new Date(this.leaverecordData.date_to);
        var day_of_leave = this.getBusinessDatesCount(startDate, endDate);
        if (day_of_leave > 1) {
          this.leaverecordData.full_day_bit = 1;
          this.leaverecordData.am_bit = 0;
          this.leaverecordData.pm_bit = 0;
        } else if (day_of_leave == 1) {
          if (
            this.leaverecordData.am_bit == 1 ||
            this.leaverecordData.am_bit == 1
          ) {
            this.leaverecordData.total_days = 0.5;
          }
        }
        //console.log(this.getBusinessDatesCount(startDate,endDate))
      },
    },
    methods: {
      checkHoliday(checkDate) {
        return this.holidays.some(function (el) {
          return el.holiday_date == checkDate;
        });
      },
      getBusinessDatesCount(startDate, endDate) {
        let count = 0;
        const curDate = new Date(startDate.getTime());
        this.date_of_leave = [];
        while (curDate <= endDate) {
          const dayOfWeek = curDate.getDay();
          const checkDate = this.getDate(curDate);
          if (
            dayOfWeek !== 0 &&
            dayOfWeek !== 6 &&
            !this.checkHoliday(checkDate)
          ) {
            count++;
            this.date_of_leave.push(checkDate);
          }
          curDate.setDate(curDate.getDate() + 1);
        }
        this.leaverecordData.total_days = count;
        return count;
        // if(((this.leaverecordData.full_day_bit+0.0) == 0))
        // {
        //   this.leaverecordData.total_days = 0.5
        // }
        // return count;
      },
      getDate(date) {
        return (
          date.getFullYear() +
          "-" +
          this.getMonthFormat(date.getMonth() + 1) +
          "-" +
          this.getMonthFormat(date.getDate())
        );
      },
      getMonthFormat(month) {
        if (month < 10) {
          return "0" + month;
        } else {
          return month;
        }
      },
      dateDisabled(ymd, date) {
        const weekday = date.getDay();
        return weekday === 0 || weekday === 6 || this.checkHoliday(ymd);
      },
      full_day_bit_changed() {
        this.reset_bit();
        this.leaverecordData.full_day_bit = 1;
        this.leaverecordData.total_days = this.date_of_leave.length;
        // if(((this.leaverecordData.full_day_bit+0.0) == 0))
        // {
        //   this.leaverecordData.total_days = 0.5
        // }else if(this.leaverecordData.date_from == this.leaverecordData.date_to)
        // {
        //   this.leaverecordData.total_days = 1
        // }
      },
      am_bit_changed() {
        this.reset_bit();
        this.leaverecordData.am_bit = 1;
        this.leaverecordData.total_days = 0.5;
      },
      pm_bit_changed() {
        this.reset_bit();
        this.leaverecordData.pm_bit = 1;
        this.leaverecordData.total_days = 0.5;
      },
      reset_bit() {
        this.leaverecordData.full_day_bit = 0;
        this.leaverecordData.am_bit = 0;
        this.leaverecordData.pm_bit = 0;
      },
    },
    setup(props, { emit }) {
      const loading = ref(false);
  
      const date_of_leave = ref([]);
      const blankLeaveRecordData = {
        id: 0,
        admin_remark: "",
        total_days: 0,
        date_from: "",
        date_to: "",
        time_from: "",
        time_to: "",
        count_al_balance: false,
        approve_bit: false,
        record_bit: false,
        full_day_bit: false,
      };
      const button_text = ref("Add");
  
      const leaverecordData = ref(
        JSON.parse(JSON.stringify(blankLeaveRecordData))
      );
  
      const resetleaverecordData = () => {
        leaverecordData.value = JSON.parse(JSON.stringify(blankLeaveRecordData));
      };
  
      const toast = useToast();
  
      watch(
        () => [props.id, props.isAddNewLeaveRecordSidebarActive],
        () => {
          if (props.id > 0 && props.isAddNewLeaveRecordSidebarActive) {
            leaverecordData.value = { ...props.data };
            leaverecordData.value.approve_bit =
              props.data.approve_bit == 1 ? true : false;
  
            if (leaverecordData.value.id > 0) {
              button_text.value = "Edit";
            } else {
              button_text.value = "Add";
            }
            console.log("leaverecord", leaverecordData.value);
          } else {
            button_text.value = "Add";
          }
        }
      );
  
      const onSubmit = () => {
        loading.value = true;
        store
          .dispatch("admin-leaverecord/updateLeaveRecord", leaverecordData.value)
          .then((response) => {
            loading.value = false;
            emit("update:is-add-new-leave-record-sidebar-active", false);
            emit("refetch-data");
            toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                text: response.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            loading.value = false;
            toast({
              component: ToastificationContent,
              props: {
                title: "Fail",
                text: error.response.data.message,
                icon: "HeartIcon",
                variant: "danger",
              },
            });
          });
      };
  
      const { refFormObserver, getValidationState, resetForm } =
        formValidation(resetleaverecordData);
  
      return {
        leaverecordData,
        onSubmit,
        refFormObserver,
        getValidationState,
        resetForm,
        button_text,
        loading,
        date_of_leave
      };
    },
  };
  </script>
  
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  #add-new-leave-record-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>