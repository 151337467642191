import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLeaveRecords(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/leave_record/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchFullLeaveRecords(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/leave_record/full_list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserFullLeaveRecords(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/leave_record/user/full_list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLeaveRecord(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/leave_record/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addLeaveRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/leave_record", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateLeaveRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/leave_record", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // This api is designed for user to update their own record.
    updateUserLeaveRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/leave_record/update", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteLeaveRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/leave_record", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/user/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportExcel(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/leave_record/export", { params: queryParams, responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportAllExcel(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/leave_record/exportAll", { params: queryParams, responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }
  },
};
